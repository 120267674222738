export const NEURON_API_URL = import.meta.env.VITE_NEURON_API_URL as string;

export const NEURON_API_TOKEN = import.meta.env.VITE_NEURON_API_TOKEN as string;

export const MODE = import.meta.env.MODE;

export const NEW_RELIC_KEY = import.meta.env.VITE_NEW_RELIC_KEY as string;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string | undefined;

export const CAS_API_KEY = import.meta.env.VITE_CDP_API_KEY as string;

export const CAS_ACTIVATION_URL = import.meta.env
  .VITE_CDP_ACTIVATION_URL as string;

export const CAS_UGC_SECTION_BANNER_ID = import.meta.env
  .VITE_CDP_UGC_ID as number;

export const CAS_CDP_HOME_ID = import.meta.env.VITE_CDP_HOME_ID as number;

export const CAS_CAMPAIGN_ID = import.meta.env.VITE_CAMPAIGN_ID as number;
export const HOME_CAS_CAMPAIGN_ID = import.meta.env
  .VITE_HOME_CAMPAIGN_ID as number;

export const CAS_SCRIPT = import.meta.env.VITE_CDP_SCRIPT as string;

export const BC_PLAYLIST_ID: string = "1775080379821060729";

export const BC_PORTRAIT_PLAYLIST_ID: string = "1761698626061298217";

export const BC_LANDSCAPE_PLAYLIST_ID: string = "1781434764065900681";

export const BC_BM_PLAYLIST_ID: string = "1754256979407047622";

export const BC_PLAYER_ID = import.meta.env.VITE_BC_PLAYER_ID as string;
export const BC_PLAYER_ID_HOME = import.meta.env
  .VITE_BC_PLAYER_ID_HOME as string;
export const BC_PLAYER_ID_VIDEO = import.meta.env
  .VITE_BC_PLAYER_ID_VIDEO as string;
export const BC_PLAYER_ID_SINGLE_PLAYER = import.meta.env
  .VITE_BC_PLAYER_ID_SINGLE_PLAYER as string;
export const BC_BM_PLAYER_ID = import.meta.env.VITE_BC_PLAYER_ID_BM as string;

export const BC_ACCOUNT_ID = import.meta.env.VITE_BC_ACCOUNT_ID as string;

export const PODCAST_ORG_ID: string = "d9486183-3dd4-4ad6-aebe-a4c1008455d5";
export const PODCAST_PLAYLIST_KOPI_KADAI: string =
  "8cc74b48-dcb5-49c0-9e81-af2a008b2e3d";
export const PODCAST_PLAYLIST_ANOTHER_SLAY: string =
  "bfa7752b-ff03-49eb-bf79-a96700cdad4d";
export const PODCAST_PLAYLIST_REPEATU: string =
  "b8f4f8e4-040d-4712-90d8-b16400a4bb12";

export const LIVEBLOG_ID = import.meta.env.VITE_LIVEBLOG_ID as string;

export const HOME_STORY_QUEUE_ID = import.meta.env
  .VITE_HOME_STORY_QUEUE_ID as string;

export const CAS_EI_MODEL_ID = import.meta.env.VITE_CDP_EI_ID as number;

export const GOOGLE_MAPS_API_KEY = import.meta.env
  .VITE_GOOGLE_MAPS_API_KEY as string;

export const EMARSYS_URL = import.meta.env.VITE_EMARSYS_BASE_URL as string;

export const DRUPAL_API_ENDPOINT = import.meta.env
  .VITE_DRUPAL_API_ENDPOINT as string;

export const LIVE_RAMP_PID = import.meta.env.VITE_LIVE_RAMP_PID as string;

export const NEURON_MOST_READ_API_TOKEN = import.meta.env
  .VITE_NEURON_MOST_READ_API_TOKEN as string;

export const NEURON_MOST_READ_BASE_URL = import.meta.env
  .VITE_NEURON_MOST_READ_BASE_URL as string;

export const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT;

export const STORY_QUEUE_ID: string | undefined = import.meta.env
  .VITE_STORY_QUEUE_ID as string | undefined;

export const ADS_DOMAIN: string =
  ENVIRONMENT == "prd"
    ? "https://adtag.sphdigital.com"
    : "https://adtag-stg.sphdigital.com";

export const EPAPER_LINK = import.meta.env.VITE_EPAPER_LINK as string;

export const COMSCORE_PUBLISHER_ID = "6288331";

export const EPAPER_API_DOMAIN: string = import.meta.env
  .VITE_EPAPER_API_DOMAIN as string;

export const GRAPESHOT_API_ENDPOINT: string = import.meta.env
  .VITE_GRAPESHOT_API_ENDPOINT as string;
