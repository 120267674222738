import downArrow from "@assets/actions/down-arrow.svg";
import LatestVideo from "@blocks/LatestVideo/LatestVideo";
import ArticleCarousel from "@components/Carousel/ArticleCarousel";
import MainBlock from "@container/MainBlock";
import AiSummary from "@elements/AiSummary/AiSummary";
import ArticleParagraph from "@elements/ArticleParagraph/ArticleParagraph";
import { ArticleGaEventsDataProps } from "@elements/ArticleParagraph/ArticleRegwall";
import GetReadingTime from "@elements/ArticleParagraph/GetReadingTime";
import Author from "@elements/Author/Author";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import ShareSocialButton from "@elements/ShareSocial/ShareSocialButton";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { StandFirst } from "@elements/Typography/Paragraph";
import { formatDate, getDateTime } from "@helper/useDateFormat";
import { getWindowOrigin, isArrayWithElements } from "@helper/utils";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import {
  ArticleAdPageTargetingType,
  ArticleAdSlotTargetingType,
} from "@typings/Ads.d";
import { ReactElement, useState } from "react";
import { twMerge } from "tailwind-merge";

const socialIconList = ["whatsapp", "facebook", "x", "copylink"];

export type ArticleContentCardProps = {
  articleData: ProcessedArticleData;
  relatedStories: ProcessedArticleData[];
  isInfiniteScrollArticle?: boolean;
  pageNumber: number;
  articlePageTarget?: ArticleAdPageTargetingType[];
  articleSlotTarget?: ArticleAdSlotTargetingType[];
  hasOutbrain?: boolean;
  gsChannels?: string[];
};

export default function ArticleContentCard({
  articleData,
  relatedStories,
  pageNumber,
  isInfiniteScrollArticle = false,
  articlePageTarget,
  articleSlotTarget,
  hasOutbrain = true,
  gsChannels,
}: ArticleContentCardProps): ReactElement {
  const {
    id,
    title,
    sectionName,
    tags,
    authors,
    dateCreated,
    dateEdited,
    kicker,
    articleBody,
    standFirst,
    urlPath,
    imageGallery,
    englishHeadline,
    englishSummary,
  } = articleData;

  const pageUrl = `${urlPath}`;

  // Get page reading time.
  const readingTime = GetReadingTime(articleBody, urlPath);
  const { isScreenLG } = useWindowSize();
  const [isDateOpen, setDateIsOpen] = useState<boolean>(false);

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setDateIsOpen((prev) => !prev);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const origin = getWindowOrigin();
  const tmCustomGaEventsProps = {
    eventLabel: title,
    articleid: id,
    cue_articleid: id,
    contenttype: "1",
    chapter1: "",
    level2: sectionName,
    pubdate: formatDate(dateCreated, "yyyy-MM-dd HH:mm:ss"),
    title: title,
    url: origin + pageUrl,
    keyword: tags ? tags.map((tags) => tags.name).join(", ") : "",
    pagination: pageNumber,
  } as ArticleGaEventsDataProps;

  return (
    <MainBlock
      showBreadcrumbs={!isInfiniteScrollArticle}
      sectionName={sectionName}
      pageName={title}
      showTitle={false}
    >
      <article className="lg:mt-1">
        <div className="font-primary font-bold text-2sm leading-430 text-black-400">
          {kicker}
        </div>

        <Heading className="text-3md lg:text-lg font-bold font-primary text-gray-100">
          {title}
        </Heading>

        <div className="w-full inline-block py-5">
          <div className="inline-block">
            <button
              onClick={toggleDropdown}
              className="dropdown-button flex leading-7"
            >
              <Date className="mr-2 text-gray-100">
                {getDateTime(dateCreated)}
              </Date>
              {dateEdited && dateEdited > dateCreated && (
                <img
                  src={downArrow}
                  alt={"dropdown-arrow"}
                  width={10}
                  height={10}
                  className={twMerge(
                    "mt-3 duration-500",
                    isDateOpen && "-rotate-180",
                  )}
                />
              )}
            </button>
            {isDateOpen && dateEdited && dateEdited > dateCreated && (
              <>
                <div className="mb-2 font-primary font-normal text-gray-150 text-xs lg:flex leading-7">
                  <span className="mr-1">மாற்றம் செய்யப்பட்ட நாள்:</span>
                  <Date className="lg:mt-0">{getDateTime(dateEdited)}</Date>
                </div>
              </>
            )}

            <div className="font-primary font-medium text-gray-150 text-xs">
              {readingTime} mins read
            </div>
          </div>

          <div className="float-right">
            <ShareSocialButton
              socialIconList={socialIconList}
              pagePath={pageUrl}
              mbPopupClassName="absolute right-0"
              deskClassName="mb-5"
            />
          </div>
        </div>

        {standFirst && (
          <StandFirst className="border-t border-solid border-gray-2100 mt-4xs mb-5 pt-xxs">
            {standFirst}
          </StandFirst>
        )}

        {isArrayWithElements(imageGallery) && (
          <div className={`article-carousel-wrapper-${id}`}>
            <ArticleCarousel
              renderItems={imageGallery}
              sectionName={sectionName}
            />
          </div>
        )}

        {isArrayWithElements(authors) && authors[0].urlPath && (
          <Author
            destination={authors[0].urlPath ? authors[0].urlPath : ""}
            authorSrc={authors[0].authorImage ? authors[0].authorImage.url : ""}
            authorName={authors[0].name}
          />
        )}

        {englishHeadline && englishSummary && (
          <AiSummary
            englishHeadline={englishHeadline}
            englishSummary={englishSummary}
            customGaEvents={tmCustomGaEventsProps}
          />
        )}

        {isArrayWithElements(articleBody) && (
          <ArticleParagraph
            articleId={id}
            articleTitle={title}
            articleBody={articleBody}
            relatedStories={relatedStories}
            sectionName={sectionName}
            pageUrl={pageUrl}
            dateCreated={dateCreated}
            tags={tags}
            authors={authors}
            pageNumber={pageNumber}
            isInfiniteScrollArticle={isInfiniteScrollArticle}
            articlePageTarget={articlePageTarget}
            articleSlotTarget={articleSlotTarget}
            gsChannels={gsChannels}
          />
        )}
      </article>

      {/* Video block */}
      {isScreenLG && !isInfiniteScrollArticle && <LatestVideo />}

      {/* Outbrain block */}
      {hasOutbrain && isScreenLG && (
        <div className="border-t border-pink-300 pt-6">
          <Outbrain pathName={pageUrl} version="AR_1" />
        </div>
      )}
    </MainBlock>
  );
}
