import ChevronImage from "@assets/video_widget/Chevron.webp";
import ArticleCard from "@components/Card/ArticleCard";
import Heading from "@elements/Typography/Heading";
import {
  ProcessedArticleData,
  ProcessedArticleMapList,
} from "@helper/transformer/useOsResponse";
import useSwrApi from "@hooks/useSwrApi";
import { Link } from "react-router-dom";

export const TopNewsSection = ({
  topNewsArticles,
}: {
  topNewsArticles: ProcessedArticleData[];
}) => {
  const { data: topNewsMap } = useSwrApi<ProcessedArticleMapList>(`top-news`);
  const source = "top_news";
  topNewsArticles = topNewsMap
    ? Object.keys(topNewsMap).map((mapKey: string) => topNewsMap[mapKey])
    : [];
  return (
    <div className="flex flex-wrap gap-5 border-b border-pink-300">
      <Heading
        Element="h1"
        className="text-red-100 text-2lg lg:text-lg font-bold hover:underline hover:cursor-pointer hover:text-red-200"
      >
        <Link reloadDocument to={"/top-news"} target="_blank">
          முக்கியச் செய்திகள்
          <img
            alt="toggle-button-chevie"
            height={26}
            width={26}
            className="pl-100 inline"
            src={ChevronImage}
          />
        </Link>
      </Heading>
      {topNewsArticles.map((article, i) => (
        <ArticleCard
          key={article.id}
          displayWidth={813}
          displayHeight={527}
          {...article}
          elements={{ hasParagraph: i === 0 ? true : false, hasDate: true }}
          overrideStyle={{
            cardWrapper:
              i === 0
                ? "pt-0 pb-5 flex-col h-fit w-full max-w-150 gap-[0px!important]"
                : "pt-0 pb-5 flex-row flex-row-reverse lg:flex-col w-full lg:w-[23%] gap-1 lg:border-b-0",
            paragraphStyle: i === 0 ? "lg:text-2sm leading-400" : "leading-400",
            titleStyle:
              i === 0
                ? "font-semibold font-primary text-3md text-gray-100 hover:text-gray-100 leading-450"
                : "font-semibold font-primary text-2sm text-gray-100 hover:text-gray-100 min-h-17 leading-400",
            imageWrapperStyle:
              i === 0
                ? "w-full max-w-full lg:w-full sm:w-full h-55 lg:h-130 sm:h-100 lg:max-h-130"
                : "w-23 max-w-45 lg:w-45 lg:h-31.25 lg:max-h-31.25 sm:w-32",
            contentWrapper: i === 0 ? "p-sm bg-gray-500" : "gap-2.5",
            titleWrapper: "gap-0",
            imageStyle: i === 0 ? " h-55 lg:h-130 sm:h-100" : "h-23 lg:h-31.25",
          }}
          refMedium={source}
        />
      ))}
    </div>
  );
};
