import WrapperBlock from "@container/WrapperBlock";
import { CompanionAdsImu2 } from "@elements/Advertisement/variant/CompanionAdImu";
import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import RenderArticleBlocks from "@elements/RenderArticleBlocks/RenderArticleBlocks";
import debounce from "@helper/debounce";
import fetchTMApi from "@helper/fetchTMApi";
import { formatDate } from "@helper/useDateFormat";
import {
  getWindowOrigin,
  isArrayWithElements,
  isClientSide,
} from "@helper/utils";
import { isElementInViewPort } from "@helper/visibilityHelpers";
import {
  useComscoreCall,
  useTMCustomEventAnalyticsCall,
} from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import {
  CreateLoadMoreHandler,
  CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import ArticleContentCard from "@pages/Article/ArticleContentCard";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetValue } from "@typings/Ads";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import React, { ReactElement, useCallback, useEffect, useState } from "react";

type ArticleInfiniteScrollProps = {
  mainArticle: ProcessedArticleData;
  pageAdTargetValue: PageAdTargetValue;
  onNewArticle: (article: ProcessedArticleData) => void;
  hasOutbrain?: boolean;
  gsChannels?: string[];
};

export default function ArticleInfiniteScroll({
  mainArticle,
  pageAdTargetValue,
  onNewArticle,
  hasOutbrain = true,
  gsChannels,
}: ArticleInfiniteScrollProps): ReactElement {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [newArticleGaLoaded, setNewArticleGaLoaded] = useState<boolean>(false);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>([mainArticle.id]),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [visibleArticleId, setVisibleArticleId] = useState<string>(
    mainArticle.id,
  );
  const [currentPath, setCurrentPath] = useState<string>(
    mainArticle.urlPath || "",
  );
  const [latestArticle, setLatestArticle] = useState<ProcessedArticleData>();
  const [updatedPathForArticleId, setUpdatedPathForArticleId] =
    useState<string>();

  const [relatedStories, setRelatedStories] = useState<ProcessedArticleData[]>(
    [],
  );

  const TmCustomGaEvent = useTMCustomEventAnalyticsCall();
  const dataToComScore = useComscoreCall();

  const filterArticlesBasedOn = mainArticle.sectionName;

  const origin = getWindowOrigin();

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? mainArticle : loadMoreData.at(-1);

    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn,
      excludeIds: [...renderedArticleIds].join(","),
    };
    // can be moved into a helper folder
    const fetchFunction = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[]> => {
      const response: ProcessedArticleData[] = await fetchTMApi(
        "get-article",
        "POST",
        {
          section: requestData.filterArticlesBasedOn,
          excludeIds: requestData.excludeIds,
        },
      );
      return response;
    };

    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: 1,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);

    if (isArrayWithElements(response)) {
      const article = response[0];
      setVisibleArticleId(article.id);
      setUpdatedPathForArticleId(article.id);
      onNewArticle(article);
      setLatestArticle(article);

      // Load GA events on infinite scroll.
      TmCustomGaEvent({
        event: "infiniteScrollArticle",
        articleid: article.id,
        byline: article.authors
          ? article.authors.map((author) => author.name).join(", ")
          : "",
        contenttype: "article",
        keyword: article.tags
          ? article.tags.map((tags) => tags.name).join(", ")
          : "",
        landingPage: origin + mainArticle.urlPath,
        nextNodeTitle: article.title,
        nextNodeUrl: origin + article.urlPath,
        pubdate: formatDate(article.dateCreated, "yyyy-MM-dd HH:mm:ss"),
        pagination: pageNumber == 0 ? 1 : pageNumber,
      });
    } else {
      setHasMoreStories(false);
    }

    return response;
  };

  const handleScroll = useCallback(() => {
    if (!isClientSide) return;

    const articleCards = document.querySelectorAll(".article-card");

    articleCards.forEach((article) => {
      if (article.id !== updatedPathForArticleId) {
        if (isElementInViewPort(article, 20)) {
          setVisibleArticleId(article.id);
        }
      }
    });

    // Set GA event once article is read.
    const articleBody = document.querySelectorAll(
      `.article-carousel-wrapper-${updatedPathForArticleId}`,
    );
    articleBody.forEach((articleData) => {
      if (isElementInViewPort(articleData, 65)) {
        setNewArticleGaLoaded(true);
      } else {
        setNewArticleGaLoaded(false);
      }
    });
  }, [updatedPathForArticleId]);

  useEffect(() => {
    if (latestArticle && newArticleGaLoaded) {
      TmCustomGaEvent({
        event: "virtualPV",
        articleid: latestArticle.id,
        byline: latestArticle.authors
          ? latestArticle.authors.map((author) => author.name).join(", ")
          : "",
        chapter1: "",
        contenttype: "article",
        cue_articleid: latestArticle.id,
        keyword: latestArticle.tags
          ? latestArticle.tags.map((tags) => tags.name).join(", ")
          : "",
        landingPage: origin + mainArticle.urlPath,
        level2: latestArticle.sectionName,
        nextNodeTitle: latestArticle.title,
        nextNodeUrl: origin + latestArticle.urlPath,
        pubdate: formatDate(latestArticle.dateCreated, "yyyy-MM-dd HH:mm:ss"),
        title: mainArticle.title,
      });
      dataToComScore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newArticleGaLoaded]);

  useEffect(() => {
    if (!isClientSide) return;
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isClientSide) {
      const articleData = [mainArticle, ...loadMoreData].find(
        (article) => article.id === visibleArticleId,
      );

      if (articleData) {
        const newPath = articleData.urlPath;
        if (newPath !== currentPath) {
          const RelatedStoryId = articleData["relatedArticles"].map(
            (story) => story.id,
          );
          // can be moved into a helper folder
          const fetchNextStoryRelatedArticleData = async (
            requestData: string[],
          ): Promise<ProcessedArticleData[]> => {
            const response: ProcessedArticleData[] = await fetchTMApi(
              "get-related-article",
              "POST",
              {
                RelatedStoryIds: requestData,
              },
            );
            return response;
          };
          void fetchNextStoryRelatedArticleData(RelatedStoryId).then((e) => {
            setRelatedStories(e);
          });

          window.history.pushState("article", articleData.title, newPath);
          setCurrentPath(newPath || "");
          setUpdatedPathForArticleId(articleData.id);
          onNewArticle(articleData);
        }
      }
    }
  }, [visibleArticleId, loadMoreData, mainArticle, onNewArticle, currentPath]);

  const { isScreenLG } = useWindowSize();
  return (
    <>
      {[...loadMoreData].map((context, index) => (
        <React.Fragment key={index}>
          <div id={context.id} className="article-card mt-10">
            {isScreenLG && (
              <CompanionAdsLb
                uniqueSectionName={filterArticlesBasedOn}
                index={index + 2}
                pageAdTargetType={pageAdTargetValue}
                gsChannels={gsChannels}
                articleSlotTarget={[
                  {
                    key: "tmarticleid",
                    value: context.id,
                  },
                  {
                    key: "tmtags",
                    value:
                      context.tags?.map((tags) => tags.name).join(", ") || "",
                  },
                ]}
                rootClassName={
                  "bg-red-500 lg:mb-10 lg:pt-xm lg:pb-5 relative lg:w-[100vw] xl:left-[-95px] xl:max-w-[1440px] xl:w-[calc(100%+190px)]"
                }
              />
            )}
            <WrapperBlock className="hover:text flex flex-col lg:flex-row justify-between">
              <ArticleContentCard
                articleData={context}
                relatedStories={relatedStories}
                pageNumber={index + 1}
                isInfiniteScrollArticle={true}
                hasOutbrain={hasOutbrain}
                gsChannels={gsChannels}
                articleSlotTarget={[
                  { key: "page_number", value: (index + 2).toString() },
                  {
                    key: "tmarticleid",
                    value: context.id,
                  },
                  {
                    key: "tmtags",
                    value:
                      context.tags?.map((tags) => tags.name).join(", ") || "",
                  },
                ]}
              />
              <div className="lg:w-4/12 lg:pl-xs lg:pr-xxs lg:min-h-[800px] relative lg:justify-center lg:flex">
                {!isScreenLG ? (
                  <>
                    {/* Render blocks based on blockIndex */}
                    <RenderArticleBlocks
                      pageNumber={index + 1}
                      pageUrl={context.urlPath ? context.urlPath : ""}
                      articleData={context}
                      pageAdTargetValue={pageAdTargetValue}
                      gsChannels={gsChannels}
                      hasOutbrain={hasOutbrain}
                    />
                  </>
                ) : (
                  <CompanionAdsImu2
                    uniqueSectionName={context.sectionName}
                    pageAdTargetType={pageAdTargetValue}
                    pageNumber={index + 1}
                    rootClassName={
                      "lg:absolute lg:top-0 lg:bottom-1/4 lg:!h-auto"
                    }
                    gsChannels={gsChannels}
                    articleSlotTarget={[
                      {
                        key: "page_number",
                        value: (index + 1).toString(),
                      },
                      {
                        key: "tmarticleid",
                        value: context.id,
                      },
                      {
                        key: "tmtags",
                        value:
                          context.tags?.map((tags) => tags.name).join(", ") ||
                          "",
                      },
                    ]}
                  />
                )}
              </div>
            </WrapperBlock>
          </div>
        </React.Fragment>
      ))}
      <LoadMore
        rootClassName="my-4"
        onLoadMore={handleLoadMore}
        hasMore={hasMoreStories}
        loadDefaultGaEvent={false}
        landingPage={`${origin}${mainArticle.urlPath}`}
        mobileInfiniteScroll={true}
      />
    </>
  );
}
