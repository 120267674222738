import fetchTMApi from "@helper/fetchTMApi";
import { useEffect, useState } from "react";

export function useGrapeshotData(urlPath: string) {
  const [gsChannels, setGsChannels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchGsChannels = async (urlPath: string): Promise<string[]> => {
    try {
      const response = await fetchTMApi<string[]>(
        `get-gs-channels?url=${urlPath}`,
        "GET",
      );

      return response;
    } catch (e) {
      return [];
    }
  };

  const fetchWithTimeout = async (
    promise: Promise<string[]>,
    timeoutMs: number,
  ): Promise<string[]> => {
    return Promise.race([
      promise,
      new Promise<string[]>((resolve) =>
        setTimeout(() => {
          resolve([]);
        }, timeoutMs),
      ),
    ]);
  };

  useEffect(() => {
    const callFetch = async () => {
      if (urlPath === "") {
        setIsLoading(false);
        return;
      }
      const gsData = await fetchWithTimeout(fetchGsChannels(urlPath), 1000); // wait for only 1 sec.
      setGsChannels(gsData);
      setIsLoading(false);
    };

    void callFetch();
  }, [urlPath]);

  return { gsChannels, isLoading };
}
