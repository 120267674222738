import promoteEpaperdesk from "@assets/epaper/promote-epaper-dsk.svg";
import promoteEpaperMb from "@assets/epaper/promote-epaper-mb.svg";
import { Image } from "@elements/ResponsiveImage/Image";
import { EPAPER_LINK } from "@helper/getEnvVariables";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type PromotEpaperProps = {
  className?: string;
  epaperCoverImageUrl?: string;
};

export default function PromoteEpaper({
  className,
  epaperCoverImageUrl,
}: PromotEpaperProps) {
  const { isScreenMD } = useWindowSize();

  return (
    <>
      <div
        id="promote-epaper"
        className={twMerge(
          "my-5 lg:mt-0 py-5 lg:pt-0 border-y lg:border-t-0 border-gray-1050",
          className,
        )}
      >
        <Link
          reloadDocument
          to={EPAPER_LINK}
          target="_blank"
          rel="noreferrer"
          className="group block bg-gray-500 py-5 px-10 rounded-100 lg:rounded-none"
        >
          <div className="font-semibold text-2.5md text-pink-200 text-center leading-7">
            இன்று அச்சிடப்பட்ட நாளிதழை மின்னிலக்க வடிவில் படியுங்கள்
          </div>
          {isScreenMD ? (
            <Image
              objectFit="contain"
              src={
                epaperCoverImageUrl ? epaperCoverImageUrl : promoteEpaperdesk
              }
              className={twMerge(
                "mx-auto my-2.5",
                epaperCoverImageUrl ? "!aspect-epaper" : "w-[235px] h-[176px]",
              )}
              srcWidth={235}
              srcHeight={176}
              alt="promote-epaper-desk"
              dataTestId="image"
            />
          ) : (
            <Image
              objectFit="contain"
              src={epaperCoverImageUrl ? epaperCoverImageUrl : promoteEpaperMb}
              className={twMerge(
                "mx-auto",
                epaperCoverImageUrl ? "!aspect-epaper" : "w-23 h-50",
              )}
              srcWidth={90}
              srcHeight={213}
              alt="promote-epaper-mb"
              dataTestId="image"
            />
          )}
          <div className="text-xs font-sans font-semibold rounded py-2.5 px-5 text-center block mx-auto w-60 h-9 bg-red-100 text-red-500 group-hover:bg-red-200 leading-5">
            மின்னிதழைப் படிக்க
          </div>
        </Link>
      </div>
    </>
  );
}
