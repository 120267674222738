import tmTeamBackground from "@assets/about-us/team_background.png";
import { TM_TEAM } from "@pages/AboutUs/AboutUsConstants";

export default function AboutTmTeam() {
  return (
    <div
      className="pt-10 lg:pt-xxl pb-20 bg-cover bg-center"
      style={{
        backgroundImage: `url(${tmTeamBackground})`,
      }}
    >
      <div className="text-center mb-6 lg:pb-5">
        <div className="text-red-100 text-3md lg:text-md leading-650 font-primary font-semibold">
          தமிழ் முரசு குழு
        </div>
        <div className="text-grey-700 font-primary font-medium text-3.5md leading-9">
          Tamil Murasu Team
        </div>
      </div>

      <div className="xl:max-w-xl mx-auto">
        <div className="flex flex-wrap gap-4 justify-center">
          {TM_TEAM.map((item, index) => (
            <div
              key={index}
              className="w-69 py-6 p-4 text-center bg-white-100 bg-opacity-55 font-primary"
            >
              <div className="text-grey-900 text-2md leading-7 font-bold ">
                {item.tm_name}
              </div>
              <div className="text-grey-900 text-xs lg:text-2sm leading-230 lg:leading-6 font-bold">
                {item.tm_position}
              </div>
              <div className="text-grey-700 leading-7 text-2md font-medium">
                {item.en_name}
              </div>
              <div className="text-grey-700 leading-230 text-1sm font-bold">
                {item.en_postion}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
