import { TM_FOUNDER, TM_OFFERS } from "@pages/AboutUs/AboutUsConstants";

export default function AboutTmContent() {
  return (
    <div className="block lg:mt-10">
      <div className="lg:flex">
        <div className="lg:w-2/4 pr-2.5">
          <div className="mb-5 leading-450 text-3sm lg:font-semibold font-primary text-grey-900">
            {TM_FOUNDER.tm_desc}
          </div>
          <div className="leading-650 text-sm font-primary font-normal lg:font-semibold text-grey-700">
            {TM_FOUNDER.en_desc}
          </div>
        </div>
        <div className="lg:w-2/4 mt-5 lg:mt-0 lg:pl-2.5">
          <img
            width={610}
            height={435}
            alt="founder"
            src={TM_FOUNDER.image}
            className="md:w-full"
          />
          <div className="bg-red-400 p-2.5">
            <div className="leading-250 font-primary font-normal text-xs text-grey-900">
              {TM_FOUNDER.tm_caption}
            </div>
            <div className="leading-250 font-primary font-normal text-1sm text-grey-700">
              {TM_FOUNDER.en_caption}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-col lg:flex-row flex mt-5 lg:mt-10">
        <div className="order-2 lg:order-1 lg:w-2/4 lg:pr-2.5 mt-5 lg:mt-0">
          <img
            width={610}
            height={435}
            alt="tm-offers"
            src={TM_OFFERS.image}
            className="md:w-full"
          />
          <div className="bg-red-400 p-2.5">
            <div className="leading-250 font-primary font-normal text-xs text-grey-900">
              {TM_OFFERS.tm_caption}
            </div>
            <div className="leading-250 font-primary font-normal text-1sm text-grey-700">
              {TM_OFFERS.en_caption}
            </div>
          </div>
        </div>
        <div className="order-1 lg:order-2 lg:w-2/4 lg:pl-2.5">
          <div className="mb-5 leading-450 text-3sm font-semibold font-primary text-grey-900">
            {TM_OFFERS.tm_desc}
          </div>
          <div className="leading-650 text-sm font-primary font-normal lg:font-semibold text-grey-700">
            {TM_OFFERS.en_desc}
          </div>
        </div>
      </div>
    </div>
  );
}
