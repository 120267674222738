import ChevronImage from "@assets/video_widget/Chevron.webp";
import ArticleCard from "@components/Card/ArticleCard";
import Heading from "@elements/Typography/Heading";
import { ProcessedArticleMapList } from "@helper/transformer/useOsResponse";
import useSwrApi from "@hooks/useSwrApi";
import { Link } from "react-router-dom";

function getTamilSectionTitles(sectionName: string) {
  switch (sectionName) {
    case "singapore":
      return "சிங்க‌ப்பூர்";
    case "tamilnadu":
      return "தமிழ்நாடு";
    case "india":
      return "இந்தியா";
    case "world":
      return "உல‌க‌ம்";
    case "sports":
      return "விளையாட்டு";
    case "lifestyle":
      return "வாழ்வும் வளமும்";
    default:
      return "சிங்க‌ப்பூர்";
  }
}

const SectionPartition = ({ sectionName }: { sectionName: string }) => {
  const { data: sectionArticlesMap } = useSwrApi<ProcessedArticleMapList>(
    `latest-section-articles?size=3&section=${sectionName}`,
  );
  const sectionArticlesList = sectionArticlesMap
    ? Object.keys(sectionArticlesMap).map(
        (mapKey: string) => sectionArticlesMap[mapKey],
      )
    : [];
  const tamilSectionName = getTamilSectionTitles(sectionName);
  return (
    <div className="lg:w-[calc(50%-20px)] border-b border-pink-300 lg:border-b-0">
      <Heading
        Element="h2"
        className="text-red-100 text-2.5md font-bold mt-5 hover:underline hover:cursor-pointer hover:text-red-200"
      >
        <Link reloadDocument to={`/${sectionName}`} target="_blank">
          {tamilSectionName}
          <img
            alt="toggle-button-chevie"
            height={26}
            width={26}
            className="pl-100 inline"
            src={ChevronImage}
          />
        </Link>
      </Heading>
      {sectionArticlesList.map((article, i, row) => (
        <ArticleCard
          key={article.id}
          displayWidth={385}
          displayHeight={257}
          {...article}
          elements={{ hasParagraph: i === 0 ? true : false, hasDate: true }}
          overrideStyle={{
            cardWrapper:
              i === 0
                ? "flex-col"
                : i + 1 === row.length
                  ? "flex-row flex-row-reverse border-b-0"
                  : "flex-row flex-row-reverse",
            paragraphStyle:
              i === 0 ? "h-[calc(100%+10px)] overflow-hidden" : "",
            titleStyle:
              "font-semibold font-primary text-2sm text-gray-100 hover:text-gray-100",
            imageWrapperStyle:
              i === 0
                ? "w-full max-w-82 lg:w-82 min-h-55 lg:min-h-62 lg:max-h-62 sm:w-full"
                : "w-23 max-w-44 lg:w-40 lg:h-23 lg:max-h-23",
            contentWrapper: "gap-2.5",
            titleWrapper: "gap-0",
            imageStyle:
              i === 0
                ? " h-55 lg:h-62 sm:h-100"
                : "h-23 lg:h-23 w-23 lg:w-full",
          }}
          refMedium={sectionName}
        />
      ))}
    </div>
  );
};

export const SectionArticles = () => {
  const sections = [
    ["singapore", "tamilnadu"],
    ["india", "world"],
    ["sports", "lifestyle"],
  ];
  return (
    <div>
      {sections.map((row, index) => (
        <div
          key={index}
          className="flex flex-wrap gap-5 border-b-0 lg:border-b border-pink-300"
        >
          {row.map((sectionName) => (
            <SectionPartition key={sectionName} sectionName={sectionName} />
          ))}
        </div>
      ))}
    </div>
  );
};
