import { tamilSlice } from "@helper/stringTransform";
import { isArrayWithElements } from "@helper/utils";
import {
  ParagraphAnnotations,
  ParagraphInlineLinkDetails,
} from "@typings/Opensearch";
import React from "react";
import { Link } from "react-router-dom";

function wrapTextWithAnnotation(
  text: React.ReactNode,
  annotation: ParagraphAnnotations,
): React.ReactNode {
  switch (annotation.name) {
    case "bold":
      return annotation.value === "true" ? (
        <strong key={annotation.index} className="font-primary">
          {text}
        </strong>
      ) : (
        text
      );
    case "italic":
    case "subhead_italic":
      return annotation.value === "true" ? (
        <em key={annotation.index}>{text}</em>
      ) : (
        text
      );
    case "bolditalic":
      return annotation.value === "true" ? (
        <strong key={`strong-${annotation.index}`} className="font-primary">
          <em key={`em-${annotation.index}`}>{text}</em>
        </strong>
      ) : (
        text
      );
    case "capitals":
      return annotation.value === "true" ? (
        <span key={annotation.index} className="uppercase">
          {text}
        </span>
      ) : (
        text
      );
    case "external_link":
    case "internal_link": {
      const linkDetails = annotation.value as ParagraphInlineLinkDetails;
      return (
        <Link
          key={annotation.index}
          to={linkDetails.url}
          target={linkDetails.target}
          rel={linkDetails.rel}
          className="text-red-100 hover:underline hover:cursor-pointer hover:text-red-200"
        >
          {text}
        </Link>
      );
    }
    default:
      return text;
  }
}

function applyNestedAnnotations(
  text: string,
  annotations: ParagraphAnnotations[],
  startIndex: number = 0,
): React.ReactNode {
  if (!isArrayWithElements(annotations)) {
    return text;
  }

  const sortedAnnotations = [...annotations].sort((a, b) => a.index - b.index);
  const result: React.ReactNode[] = [];
  let currentIndex = 0;
  const processedIndices = new Set<number>();

  sortedAnnotations.forEach((annotation, i) => {
    const { index, length } = annotation;
    const end = index + length;

    // Skip already processed annotations
    if (processedIndices.has(index)) {
      return;
    }

    // Add text before the annotation
    if (currentIndex < index - startIndex) {
      result.push(tamilSlice(text, currentIndex, index - startIndex));
      currentIndex = index - startIndex;
    }

    // Process annotated text
    const segment = tamilSlice(text, index - startIndex, end - startIndex);
    const nestedAnnotations = sortedAnnotations
      .slice(i + 1)
      .filter(
        (nestedAnnotation) =>
          nestedAnnotation.index >= index && nestedAnnotation.index < end,
      );
    if (nestedAnnotations.length > 0) {
      const nestedResult = applyNestedAnnotations(
        segment,
        nestedAnnotations,
        index,
      );
      result.push(wrapTextWithAnnotation(nestedResult, annotation));
    } else {
      result.push(wrapTextWithAnnotation(segment, annotation));
    }

    // Mark indices as processed
    for (let j = index; j < end; j++) {
      processedIndices.add(j);
    }

    currentIndex = end - startIndex;
  });

  if (currentIndex < text.length) {
    result.push(tamilSlice(text, currentIndex));
  }

  return result;
}

export default function ApplyParagraphAnnotations(
  paragraph: string,
  annotations: ParagraphAnnotations[],
): React.ReactNode[] {
  return [applyNestedAnnotations(paragraph, annotations)];
}
