import cx from "classnames";

export type AudioPlayerProps = {
  src: string;
  height?: string;
  title?: string;
  className?: string;
};

export default function Audio({
  src,
  title = "#NoTapis",
  height = "200",
  className,
}: AudioPlayerProps) {
  return (
    <iframe
      data-testid="podcast-iframe"
      src={src}
      width="100%"
      height={height}
      title={title}
      className={cx("my-2", className)}
    ></iframe>
  );
}
