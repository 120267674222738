import trendArrow from "@assets/trend_arrow.gif";
import ArticleCard from "@components/Card/ArticleCard";
import Divider from "@elements/Divider/Divider";
import Heading from "@elements/Typography/Heading";
import { ProcessedArticleData } from "@helper/transformer/useOsResponse";
import { isArrayWithElements } from "@helper/utils";
import useSwrApi from "@hooks/useSwrApi";

export default function MostRead() {
  const { data: mostReadData } = useSwrApi<ProcessedArticleData[]>("most-read");
  const source = "most_read";
  return (
    <div className="w-full min-h-[400px] mt-5">
      {isArrayWithElements(mostReadData) ? (
        <>
          <div className="w-full bg-red-400 pt-3 px-3">
            <Heading
              Element="h2"
              className="text-gray-100 text-md font-bold inline-flex"
            >
              <img
                src={trendArrow}
                alt={"trend-arrow"}
                width={28}
                height={28}
                className="mr-1"
              />
              அதிகம் படித்தவை
            </Heading>
            {mostReadData.slice(0, 5).map((article, i, row) => (
              <ArticleCard
                displayWidth={135}
                displayHeight={90}
                key={article.id}
                elements={{ hasParagraph: false, hasDate: true }}
                overrideStyle={{
                  cardWrapper:
                    i + 1 === row.length
                      ? "flex-row border-b-0"
                      : "flex-row border-b-gray-2050",
                  titleStyle:
                    "font-semibold font-primary text-2sm text-gray-100 hover:text-gray-100",
                  imageWrapperStyle: "w-23 max-w-40 lg:w-31.5 h-23 sm:w-full",
                  contentWrapper: "gap-xs",
                  titleWrapper: "gap-0",
                  imageStyle: "w-full h-23",
                }}
                refMedium={source}
                {...article}
              />
            ))}
          </div>
          <Divider className="mt-5 border-b border-gray-2200" />
        </>
      ) : null}
    </div>
  );
}
