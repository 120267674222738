import { CinemaArticles } from "@blocks/Home/CinemaArticles";
import { HoroscopeSection } from "@blocks/Home/HoroscopeSection";
import { JobsListing } from "@blocks/Home/JobsListing";
import { MovieTimingsSection } from "@blocks/Home/MovieTimingsSection";
import { RecentArticlesSection } from "@blocks/Home/RecentArticlesSection";
import { SectionArticles } from "@blocks/Home/SectionArticles";
import { TopNewsSection } from "@blocks/Home/TopNewsSection";
import { VideoPlaylistSection } from "@blocks/Home/VideoPlaylistSection";
import PromoteEpaper from "@blocks/PromoteEpaper/PromoteEpaper";
import MainBlock from "@container/MainBlock";
import OutbrainMainContainer, {
  AdsSettings,
} from "@container/OutbrainMainContainer";
import { GoldSection } from "@container/SideBlock/GoldSection";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import Divider from "@elements/Divider/Divider";
import {
  BC_PLAYER_ID_HOME,
  HOME_CAS_CAMPAIGN_ID,
} from "@helper/getEnvVariables";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import useWindowDimensions from "@hooks/useWindowDimensions";
import HomeMetaData from "@pages/Home/HomeMetaData";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import CASBlock from "@src/app/components/Blocks/CASBlock/CASBlock";
import useCasScript from "@src/app/hooks/useCasScript";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { HomePageDataPayload } from "@typings/PagePayloadType";

export default function HomePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<HomePageDataPayload, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const adSettings: AdsSettings = useGlobalAdSetting();
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    throw new Error("Client Error");
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    throw new Error("Server Error");
  }

  const { isScriptReady: isCasScriptReady } = useCasScript();

  const { gaData, metaTags } = HomeMetaData();
  const pageAdTargetValue = PageAdTargetingTypeEnum.HOME;

  const sectionName = "/";
  const { width } = useWindowDimensions();
  const isMobile = width <= 480;

  const recentArticles = dataLoaderResponse.payload.recentArticles;
  const topNewsArticles = dataLoaderResponse.payload.topNewsArticles;
  const epaperCoverImageUrl = dataLoaderResponse.payload.epaperCoverImage;

  return (
    <OutbrainMainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
      suppliedAdsSettings={adSettings}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock showBreadcrumbs={false} showTitle={false}>
          <>
            <TopNewsSection topNewsArticles={topNewsArticles} />
            {isMobile && (
              <PromoteEpaper
                className="lg:hidden border-t-0 pt-0"
                epaperCoverImageUrl={epaperCoverImageUrl.coverImageUri}
              />
            )}
            <RecentArticlesSection recentArticles={recentArticles} />
            {isCasScriptReady && HOME_CAS_CAMPAIGN_ID && (
              <CASBlock casId={HOME_CAS_CAMPAIGN_ID} />
            )}
            <VideoPlaylistSection playerId={BC_PLAYER_ID_HOME} />
            <SectionArticles />
            {isMobile && (
              <>
                {" "}
                <GoldSection />
                <Divider className="mt-5 border-b border-gray-2200" />
              </>
            )}
            <JobsListing />
            <HoroscopeSection />
            <MovieTimingsSection />
            <CinemaArticles />
          </>
        </MainBlock>
        <SideBlock
          sectionName="home"
          hasGold={!isMobile}
          hasForex
          hasOutbrain={adSettings.isOutbrainEnabled}
          pageAdTargetValue={pageAdTargetValue}
          hasTranslate
          promoteEpaper={!isMobile}
          epaperCoverImageUrl={epaperCoverImageUrl.coverImageUri}
        />
      </WrapperBlock>
    </OutbrainMainContainer>
  );
}
