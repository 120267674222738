import ShareSocialButton from "@elements/ShareSocial/ShareSocialButton";
import { twMerge } from "tailwind-merge";
const socialIconList = ["whatsapp", "facebook", "x", "copylink"];

export default function SocialShare({
  pageUrl,
  className,
}: {
  pageUrl: string;
  className?: string;
}) {
  return (
    <div className={twMerge("flex", className)}>
      <span className="hidden lg:block mt-1 mr-6 text-grey-900 leading-6">
        Share this page:
      </span>
      <ShareSocialButton
        buttonTitle={"SHARE THIS PAGE"}
        socialIconList={socialIconList}
        pagePath={pageUrl}
      />
    </div>
  );
}
