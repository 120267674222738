import {
  adSlotSizes,
  imu1AdSlotSizesInfinteScroll,
  midArticleAdSlotSizesInfinteScroll,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

type CompanionAdsImuProps = {
  sectionName?: string;
  rootClassName?: string;
  pageAdTargetType: PageAdTargetValue;
  index: number;
  gsChannels?: string[];
};

/**
 * TMpage
 * Description: The base page component for all TM pages
 * @name - name of section or page
 * @index - use when lb1 is rendered more than 1
 */
export function CompanionAdsImu1({
  sectionName,
  rootClassName,
  pageAdTargetType,
  index,
  gsChannels,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("justify-items-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.IMU1,
        slot: GoogleAdsSlotFactory.imu1(sectionName),
        id: `dfp-ad-imu1-${index}`,
        adSlotSize: adSlotSizes["imu1"],
        pageNumber: index.toString(),
        pubAdTargeting: [PubPageAdTarget],
        gsChannels,
      }}
    />
  );
}

export function Imu1CompanionAdsInfiniteScroll({
  sectionName,
  index,
  pageAdTargetType,
  gsChannels,
}: {
  sectionName?: string;
  index: number;
  pageAdTargetType: PageAdTargetValue;
  gsChannels?: string[];
}) {
  const pageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;

  let overrideLb1Slot = imu1AdSlotSizesInfinteScroll["desktop"];
  if (typeof window !== "undefined" && screen.width < 767) {
    overrideLb1Slot = imu1AdSlotSizesInfinteScroll["mobile"];
  }

  return (
    <div className="justify-items-center w-full">
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.IMU1,
          slot: GoogleAdsSlotFactory.imu1(sectionName),
          id: index === 1 ? `dfp-ad-imu1` : `dfp-ad-imu1-${index}`,
          rootClassName: "m-0",
          adSlotSize: overrideLb1Slot,
          pageNumber: index.toString(),
          pubAdTargeting: [pageAdTarget],
          gsChannels,
        }}
      />
    </div>
  );
}

export function CompanionAdsMidArticle({
  sectionName,
  rootClassName,
  pageAdTargetType,
  index,
  gsChannels,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("justify-items-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.MIDARTICLESPECIAL,
        slot: GoogleAdsSlotFactory.midarticlespecial(sectionName),
        id: `dfp-ad-midarticlespecial-${index}`,
        adSlotSize: midArticleAdSlotSizesInfinteScroll["midArticle"],
        pageNumber: index.toString(),
        pubAdTargeting: [PubPageAdTarget],
        gsChannels,
      }}
    />
  );
}
