import "@elements/FestivalBanner/FestivalBanner.css";

import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type FestivalWrapperBlockProps = Readonly<{
  children: ReactNode;
  className: string;
}>;

export default function FestivalWrapper({
  children,
  className,
}: FestivalWrapperBlockProps) {
  return (
    <>
      <div data-testid="festival-wrapper" className={twMerge("", className)}>
        <div className="bg-white-100 xl:max-w-[1440px] w-full mx-auto">
          {children}
        </div>
      </div>
    </>
  );
}
