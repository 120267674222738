/* eslint-disable react-hooks/rules-of-hooks */
import ArticleCard from "@components/Card/ArticleCard";
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import { Imu1CompanionAdsInfiniteScroll } from "@elements/Advertisement/variant/AdsCompanion";
import Heading from "@elements/Typography/Heading";
import fetchTMApi from "@helper/fetchTMApi";
import { displayAfter, hideAfter } from "@helper/utils";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@hooks/useLoadMore/SetFirstRenderedArticleIds";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import ErrorPage from "@pages/ErrorPage";
import { KeywordMetaData } from "@pages/Keyword/KeywordMetaData";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import { KeywordPayloadResponseType } from "@typings/PagePayloadType";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

export default function GePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<KeywordPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const keywordArticles = dataLoaderResponse.payload.data;
  const tagAlias = dataLoaderResponse.payload.tagAlias;

  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);

  const showLoadMore: boolean = keywordArticles.length == INITIAL_ARTICLE_COUNT;
  useSetFirstRenderedArticleIds(keywordArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? keywordArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: tagAlias,
      sort: lastLoadedArticle?.sort,
    };

    const fetchMoreTagsStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const response: ProcessedArticleData[] = await fetchTMApi(
        "more-tags-stories",
        "POST",
        {
          tagAlias: requestData.filterArticlesBasedOn,
          sort: requestData.sort,
          size: 5,
        },
      );

      return response;
    };
    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreTagsStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };

  const { isScreenLG } = useWindowSize();

  const tagName = "பொதுத் தேர்தல் 2025";
  const showBreadcrumbs = false;
  const showTitle = false;
  const pageAdTargetValue = PageAdTargetingTypeEnum.TAG;
  const sectionName = "keywords";

  const { gaData, metaTags } = KeywordMetaData({
    tagName,
    tagAlias,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <div className="ndp-section-title bg-pink-200 lg:h-15 h-13 lg:mb-2">
        <div className="inline-flex">
          <div className="py-xxs pr-md pl-3">
            <Link reloadDocument to="/keywords/national-day-2024">
              <Heading
                Element="h1"
                className={twMerge(
                  "font-bold font-primary lg:text-1xl leading-700 mt-xxs bg-gradient-to-r from-white-100 to-yellow-100 bg-clip-text text-transparent text-3md",
                )}
              >
                {tagName}
              </Heading>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-2">
        <iframe
          width="100%"
          height={isScreenLG ? "640" : "1024"}
          title="Boundry Map - Singapore General Elections"
          src={
            "https://elections.data.gov.sg/ta/map?isScrollable=false&primaryColor=%236253E8&view=Boundary%20Change&lang=ta&year=2020&constituenciesView=all"
          }
        ></iframe>
      </div>
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock
          sectionName={tagName}
          showBreadcrumbs={showBreadcrumbs}
          showTitle={showTitle}
        >
          {[...keywordArticles, ...loadMoreData].map((section, index) => (
            <Fragment key={section.id}>
              <ArticleCard
                displayWidth={260}
                displayHeight={192}
                elements={{ hasDate: true }}
                {...section}
                overrideStyle={{
                  cardWrapper:
                    (displayAfter(index + 1, PAGE_SIZE)
                      ? "border-none"
                      : "flex-col lg:flex-row") +
                    (hideAfter(index, PAGE_SIZE)
                      ? " lg:flex-row flex-row-reverse"
                      : ""),
                  titleStyle: hideAfter(index, PAGE_SIZE)
                    ? "font-semibold text-2sm text-gray-100 hover:text-gray-100 lg:text-2lg lg:text-red-100 lg:hover:text-red-200"
                    : "text-md lg:text-2lg",
                  paragraphStyle: hideAfter(index, PAGE_SIZE)
                    ? "hidden lg:block"
                    : "",
                  imageWrapperStyle: hideAfter(index, PAGE_SIZE)
                    ? "w-23 h-23 lg:h-auto"
                    : "",
                  imageStyle: hideAfter(index, PAGE_SIZE)
                    ? "h-23 lg:h-auto"
                    : "",
                }}
              />
              {displayAfter(index + 1, PAGE_SIZE) && (
                <Imu1CompanionAdsInfiniteScroll
                  sectionName={sectionName}
                  index={(index + 1) / PAGE_SIZE}
                  pageAdTargetType={pageAdTargetValue}
                />
              )}
            </Fragment>
          ))}
          {showLoadMore ? (
            <div className="flex w-full justify-center mb-xl">
              <LoadMore
                rootClassName="my-4 w-full"
                onLoadMore={handleLoadMore}
                hasMore={hasMoreStories}
                loadText="Load More"
                mobileInfiniteScroll={true}
              />
            </div>
          ) : null}
        </MainBlock>
        <SideBlock
          sectionName={tagName}
          className="w-full lg:block"
          pageAdTargetValue={pageAdTargetValue}
        />
      </WrapperBlock>
    </MainContainer>
  );
}
