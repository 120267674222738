import { isClientSide } from "@helper/utils";
import {
  SCREEN_LG,
  SCREEN_MD,
  SCREEN_SM,
  SCREEN_XL,
  SCREEN_XS,
  SCREEN_XXS,
} from "@hooks/useWindowSize/constants";
import { useEffect, useState } from "react";

/**
 * @returns {object} The current window size state.
 */
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  const isScreenXL = windowSize.width >= SCREEN_XL;
  const isScreenLG = windowSize.width >= SCREEN_LG;
  const isScreenMD = windowSize.width >= SCREEN_MD;
  const isScreenSM = windowSize.width >= SCREEN_SM;
  const isScreenXS = windowSize.width >= SCREEN_XS;
  const isScreenXXS = windowSize.width >= SCREEN_XXS;
  const isScreenBelowXXS = windowSize.width < SCREEN_XXS;

  useEffect(() => {
    // useEffect is only called client-side.
    // To prevent SSR issues, we check if window is defined.
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    if (isClientSide) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    isScreenXL,
    isScreenLG,
    isScreenMD,
    isScreenSM,
    isScreenXS,
    isScreenXXS,
    isScreenBelowXXS,
  };
}

export { useWindowSize };
