import { Link } from "react-router-dom";

type FooterLinksProps = {
  testId?: string;
};

export function FooterLinks({ testId }: FooterLinksProps) {
  return (
    <div data-testid={`${testId}-footerLinks`} className="text-2sm leading-400">
      <Link
        reloadDocument
        to="/about-us"
        rel="noreferrer"
        className="text-red-100 hover:text-red-200 hover:underline"
      >
        தமிழ் முரசு பற்றி
      </Link>
      <span className="text-gray-100"> |&nbsp;</span>
      <Link
        to="https://www.sph.com.sg/legal/sph_privacy/"
        target="_blank"
        rel="noreferrer"
        className="text-red-100 hover:text-red-200 hover:underline"
      >
        ரகசியகாப்புக் கொள்கை
      </Link>
      <span className="text-gray-100"> |&nbsp;</span>
      <Link
        to="https://www.sph.com.sg/legal/website_tnc/"
        target="_blank"
        rel="noreferrer"
        className="text-red-100 hover:text-red-200 hover:underline"
      >
        நிபந்தனைகள் மற்றும் விதிமுறைகள்
      </Link>
      <span className="text-gray-100"> |&nbsp;</span>
      <Link
        to="https://www.sph.com.sg/legal/pdpa/"
        target="_blank"
        rel="noreferrer"
        className="text-red-100 hover:text-red-200 hover:underline"
      >
        தரவு பாதுகாப்புக் கொள்கை
      </Link>
    </div>
  );
}
