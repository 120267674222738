import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import CatfishAd from "@elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import PrestitialAd from "@elements/Advertisement/PrestitialAd";
import { AdsLb1 } from "@elements/Advertisement/variant/AdsLb";
import FestivalWrapper from "@elements/FestivalBanner/FestivalWrapper";
import type { GADataProps } from "@elements/GAData/GAData";
import GAData from "@elements/GAData/GAData";
import MetaTags from "@elements/MetaTags/MetaTags";
import { type MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { Footer } from "@footer/Footer";
import { Header } from "@header/Header";
import { HEADER_MAIN_MENU } from "@header/HeaderMenuConstants";
import { HeaderMenuList } from "@header/HeaderMenuList";
import { ManavarMurasuHeader } from "@header/ManavarMurasu/ManavarMurasuHeader";
import getPageDetails from "@helper/getPageDetails";
import { useNeuronPageViewPush } from "@hooks/useAnalyticsCall/useNeuronPageViewPush";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import useOKTAUserInfo from "@hooks/useOktaUserInfo";
import useScript from "@hooks/useScript";
import useSendPermutiveIdentify from "@hooks/useSendPermutiveIdentify";
import { AdTargetingType, PageAdTargetValue } from "@typings/Ads.d";
import { ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type Props = Readonly<{
  testId?: string;
  children: ReactNode;
  gaData?: GADataProps;
  metaTagsProps?: MetaTagsProps;
  sectionName?: string;
  hasLb1Ads?: boolean;
  mobwebview?: boolean;
  pageAdTargetValue: PageAdTargetValue;
  gsChannels?: string[];
}>;

/*
 * Container to provide a fixed width and horizontal padding around the content. Typically paired
 * with a Layout component to create reusable templates across all pages. Width of child content as
 * follows with each screen size:

  Viewport width >= 1280: Children component width: 1150px
  Viewport width >= 1024: Children component width: 990px
  Viewport width >= 992: Children component width: 960px
  Viewport width >= 768: Children component width: 720px
  Viewport width >= 576: Children component width: 540px
  All other viewport widths: Children component width: 100% - padding 30px

 * @param {ReactElement} children - Any React Element but likely used to wrap a Tamil Murasu module
 * @param {String} testId - Unique string used to target elements when testing
 * @returns {ReactElement} - A wrapped module with a fixed width that resizes on view port resize
 */

export default function MainContainer({
  children,
  testId = "main-container",
  gaData,
  metaTagsProps,
  sectionName,
  hasLb1Ads = true,
  mobwebview = false,
  pageAdTargetValue,
  gsChannels,
}: Props): ReactElement {
  const { hasDesktopNav } = getPageDetails(sectionName) || {
    hasDesktopNav: true,
  };

  const { isPrestitialEnabled, isCatfishEnabled } = useGlobalAdSetting();

  useOKTAUserInfo();
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX as string,
  });
  useNeuronPageViewPush();
  useSendPermutiveIdentify();

  if (sectionName === "maanavar-murasu") {
    hasLb1Ads = false;
  }
  sectionName = sectionName == "/" ? "home" : sectionName;
  const pageAdTarget = pubPageAdTargeting[pageAdTargetValue] as AdTargetingType<
    string,
    PageAdTargetValue
  >;

  return (
    <>
      <FestivalWrapper className="festival">
        {!mobwebview && <Header sectionName={sectionName} />}
        {sectionName === "maanavar-murasu" && <ManavarMurasuHeader />}
        <div
          data-testid={testId}
          className={twMerge(
            "xl:max-w-xl w-full mx-auto xxs:px-sm lg:p-0 max-xsm:px-1",
            sectionName === "about-us" &&
              "xl:max-w-full xxs:!px-0 max-xsm:!px-0",
          )}
        >
          {hasDesktopNav && (
            <div className="hidden lg:block sticky top-0 z-50 bg-white-100">
              <HeaderMenuList
                testId="desktop-main-links"
                items={HEADER_MAIN_MENU}
                type="main"
                isHamburger={false}
              />
            </div>
          )}
          {hasLb1Ads && !mobwebview && (
            <AdsLb1
              sectionName={sectionName}
              pageAdTargetType={pageAdTargetValue}
            />
          )}
          <PrestitialAd
            slot={GoogleAdsSlotFactory.prestitial(sectionName)}
            isPrestitialEnabled={isPrestitialEnabled}
            pubAdTargeting={[pageAdTarget]}
          />
          {gaData && <GAData {...gaData} />}
          {metaTagsProps && <MetaTags {...metaTagsProps} />}
          {children}
        </div>
        {!mobwebview && (
          <Footer
            className={sectionName === "about-us" ? "mt-0 lg:mt-500" : ""}
          />
        )}
        <CatfishAd
          slot={GoogleAdsSlotFactory.catfish(sectionName)}
          isCatfishEnabled={isCatfishEnabled}
          pubAdTargeting={[pageAdTarget]}
          gsChannels={gsChannels}
        />
      </FestivalWrapper>
    </>
  );
}
