import HeaderBanner from "@assets/about-us/header_img.png";
import MainContainer from "@container/MainContainer";
import WrapperBlock from "@container/WrapperBlock";
import AboutTmContent from "@pages/AboutUs/AboutTmContent.tsx";
import AboutTmHistory from "@pages/AboutUs/AboutTmHistory.tsx";
import AboutTmTeam from "@pages/AboutUs/AboutTmTeam.tsx";
import SocialShare from "@pages/AboutUs/helper/SocialShare";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

export default function AboutUs() {
  const sectionName = "about-us";
  const pageUrl = "/about-us";
  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
  return (
    <MainContainer
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="justify-between">
        <div
          className="flex h-64 text-center bg-cover bg-center items-center justify-center"
          style={{
            backgroundImage: `url(${HeaderBanner})`,
          }}
        >
          <div className="font-primary font-semibold">
            <div className="text-orange-500 text-3md lg:text-4xl leading-650 lg:leading-9">
              தமிழ் முரசு பற்றி
            </div>
            <div className="text-gray-2250 text-3.5md lg:text-x2l leading-9 lg:leading-960">
              About Tamil Murasu
            </div>
          </div>
        </div>

        <div className="xl:max-w-xl xxs:px-sm lg:px-5 mx-auto mt-5 lg:mt-10 md:max-w-2sm lg:max-w-full mb-10 lg:mb-xxl">
          <SocialShare pageUrl={pageUrl} />
          <AboutTmContent />
        </div>
        <AboutTmHistory />
        <AboutTmTeam />

        <div className="xl:max-w-xl xxs:px-sm lg:px-5 mx-auto pt-5 lg:pt-10 md:max-w-2sm lg:max-w-full">
          <SocialShare
            pageUrl={pageUrl}
            className="justify-center md:justify-normal"
          />
        </div>
      </WrapperBlock>
    </MainContainer>
  );
}
