import founderImage from "@assets/about-us/founder-publisher-img.svg";
import image1 from "@assets/about-us/Tm_history_1.svg";
import image2 from "@assets/about-us/Tm_history_2.svg";
import image3 from "@assets/about-us/Tm_history_3.svg";
import image4 from "@assets/about-us/Tm_history_4.svg";
import image5 from "@assets/about-us/Tm_history_5.svg";
import image6 from "@assets/about-us/Tm_history_6.svg";
import image7 from "@assets/about-us/Tm_history_7.svg";
import image8 from "@assets/about-us/Tm_history_8.svg";
import TmOffersImage from "@assets/about-us/tm-offers-img.svg";

export const TM_FOUNDER = {
  image: founderImage,
  tm_desc:
    "சிங்கப்பூரில் வெளிவரும் ஒரே தமிழ் நாளிதழான தமிழ் முரசு 1935 முதல் வெளிவருகிறது. தமிழர் சீர்திருத்தச் சங்கத்தின் கொள்கை ஏடாக, 6 ஜூலை 1935 அன்று தமிழ் முரசின் முதல் இதழ் வெளியிடப்பட்டது. அதன் செயலாளராக இருந்த திரு கோ. சாரங்கபாணி பின்னர் இதழின் ஆசிரியர் பொறுப்பை ஏற்றார்.",
  en_desc:
    "Tamil Murasu is Singapore’s only official Tamil-language daily newspaper, published since its founding on July 6, 1935. Established by the Tamil Reform Association (TRA) management committee, the publication was guided by G. Sarangapany, then secretary of the TRA, who also served as its first editor.",
  tm_caption:
    "தமிழ் முரசின் நிறுவனரும் வெளியீட்டாளருமான தமிழவேள் கோ. சாரங்கபாணி.",
  en_caption:
    "Founder and publisher of Tamil Murasu, Thamizhavel G Sarangapany.",
};

export const TM_OFFERS = {
  image: TmOffersImage,
  tm_desc:
    "தொடக்க காலத்திலிருந்தும் சிங்கப்பூர் பிரஸ் ஹோல்டிங்ஸ் நிறுவனத்துடன் இணைந்த பின்னரும், சிங்கப்பூர்த் தமிழ்ச் சமூகம் அறிந்த, அதிகாரமுள்ள, ஒற்றுமையான சமூகமாகத் திகழ தமிழ் முரசு துணைபுரிந்து வருகிறது. இப்போது அதன் 90வது ஆண்டு நிறைவை நெருங்கும் தமிழ் முரசு, அச்சு, மின்னிலக்க வடிவங்களில் செழித்து வளர்கிறது. ‘சமூகத்தின் குரல்’ என்ற அதன் முழக்க வரிக்கு உண்மையாக இருந்து வருகிறது.",
  en_desc:
    "From its early roots there to becoming part of Singapore Press Holdings decades later, Tamil Murasu has informed, empowered, and united the community over the years.Now approaching its 90th anniversary, Tamil Murasu thrives in print and digital formats, staying true to its tagline, “Voice of the Community.”",
  tm_caption:
    "இளைய, முதிய வாசகர்களுக்காக அச்சு வடிவிலும் மின்னிலக்கத் தளத்திலும் பலதரப்பட்டவற்றைத் தமிழ் முரசு வழங்குகிறது.",
  en_caption:
    "Tamil Murasu offers a diverse range of products, catering to audiences both young and old, across print and digital platforms.",
};

export const TM_HISTORY = [
  {
    image: image1,
    tm_caption: "மே 5, 1936ஆம் ஆண்டு தமிழ் முரசு அச்சுப் பிரதி.",
    en_caption: "Print edition of Tamil Murasu on 5 May 1936.",
    tm_desc:
      "தமிழ் முரசு நிறுவப்பட்டது. வார இதழாகத் தொடங்கப்பட்ட முரசு மூன்று மாதங்களில் வாரம் மும்முறை வெளிவரத் தொடங்கியது.",
    en_desc:
      "Tamil Murasu is founded. Initially launched as a weekly, Tamil Murasu quickly gained traction, expanding to a thrice-weekly schedule within three months",
    date: "Jul 6, 1935",
  },
  {
    image: image2,
    tm_desc:
      "அது எட்டுப் பக்கங்களாக வளர்ந்து, 3 காசு என்ற விலையில் விற்கப்பட்டு, 1937 டிசம்பர் 1 அன்று நாளிதழாக உருமாறியது.",
    en_desc:
      "By this time, Tamil Murasu has grown to eight pages, is sold at 3 cents per copy, and became a daily on December 1, 1937.",
    date: "May 2, 1936",
  },
  {
    image: image3,
    tm_desc:
      "கிட்டத்தட்ட 40 ஆண்டுகள் தமது தலைமைத்துவத்தில் நாளிதழை நடத்திய திரு சாரங்கபாணி 1974இல் காலமானதைத் தொடர்ந்து அவரது குடும்பத்தினர் இதழை நடத்தினர்.",
    en_desc:
      "Mr Sarangapany led Tamil Murasu for nearly 40 years until his passing in 1974, after which his family took over the publication.",
    date: "1974",
  },
  {
    image: image4,
    tm_desc:
      "படவிளக்கம்: வி.டி. அரசு என அழைக்கப்பட்ட வைத்தியநாதன்  திருநாவுக்கரசு 1989 முதல் 2000 வரை தமிழ் முரசு நாளிதழின் தலைமை ஆசிரியராகச் செயல்பட்டார்.",
    en_desc:
      "Vaidyanathan Thirunavukkarasu, popularly known as V. T. Arasu, was Tamil Murasu's chief editor from 1989 to 2000.",
    date: "1989",
  },
  {
    image: image5,
    tm_caption:
      "1990ஆம் ஆண்டில் லாவெண்டர் ஸ்திரீட்டில் இருந்த தமிழ் முரசின் சொந்த அலுவலகத்தில் முரசு ஊழியர்கள்.",
    en_caption:
      "Tamil Murasu's staff at their office in Lavender Street in 1990.",
    tm_desc:
      "சாரங்கபாணியின் மகள் ராஜமும் திருநாவுக்கரசும் பங்குதாரர்களாக இருந்த ‘ஹைப்ரோ பிரிண்டிங்’ நிறுவனத்தின் உரிமையானது தமிழ் முரசு.",
    en_desc:
      "Shareholders of HiPro Printing, Rajam Sarangapany and V.T. Arasu, owned the rights to Tamil Murasu.",
    date: "1993",
  },
  {
    image: image6,
    tm_caption: "3 நவம்பர் 1995ல் தமிழ் முரசு அச்சுப்பிரதியின் முன்பக்கம் ",
    en_caption: "Front page of Tamil Murasu newspaper on 3 Nov 1995",
    tm_desc:
      "'சிங்கப்பூர் பிரஸ் ஹோல்டிங்ஸ்' நிறுவனத்தின் நாளிதழ்களுள் ஒன்றாக முதல் தமிழ் முரசு வெளிவரத் தொடங்கியது.",
    en_desc:
      "Singapore Press Holdings (SPH) acquired HiPro Printing, officially bringing Tamil Murasu into its stable of publications.",
    date: "Nov 1, 1995",
  },
  {
    image: image7,
    tm_caption:
      "சின் சுவீ சாலையிலிருந்த தனது அலுவலகத்தில், 64 ஆண்டு வரலாற்றைக் கொண்ட தமிழ் முரசு புது வடிவத்தில் மறுஅறிமுகம் செய்யப்பட்டது.",
    en_caption:
      "Relaunch of the 64-year-old Tamil Murasu newspaper with a new design at its office in Chin Swee Road.",
    tm_desc:
      "தமிழ் முரசு இப்போது எஸ்பிஎச் மீடியா நிறுவனத்தால் இயக்கப்படுகிறது.",
    en_desc: "Tamil Murasu is now operated by SPH Media.",
    date: "Dec 1, 2021",
  },
  {
    image: image8,
    tm_caption:
      "இந்திய மரபுடைமைக்கு நிலையத்திற்கு வெளியே இடம்பெற்ற தமிழ் முரசு செயலியின் அறிமுக நிகழ்வு.",
    en_caption:
      "Launch of the Tamil Murasu app outside of the Indian Heritage Centre.",
    tm_desc: "தமிழ் முரசு செயலி வெற்றிகரமாக அறிமுகம் கண்டுள்ளது.",
    en_desc: "The Tamil Murasu app is successfully launched.",
    date: "Oct 29, 2023",
  },
];

export const TM_TEAM = [
  {
    tm_name: "த.ராஜசேகர்",
    tm_position: "ஆசிரியர்",
    en_name: "T. Raja Segar",
    en_postion: "Editor",
  },
  {
    tm_name: "இர்ஷாத் முஹம்மது",
    tm_position: "செய்தி ஆசிரியர்",
    en_name: "Irshath Mohamed",
    en_postion: "News Editor",
  },
  {
    tm_name: "எஸ்.வெங்கடேஷ்வரன்",
    tm_position: "வாசகர் & வளர்ச்சி ஆசிரியர்",
    en_name: "Venga Subramaniam",
    en_postion: "Audience & Growth Editor",
  },
  {
    tm_name: "கனகலதா கிருஷ்ணசாமி",
    tm_position: "இணை ஆசிரியர்",
    en_name: "Kanagalatha Krishnasamy",
    en_postion: "Associate Editor",
  },
  {
    tm_name: "வீ.பழனிச்சாமி",
    tm_position: "இணை ஆசிரியர்",
    en_name: "V. Palanisamy",
    en_postion: "Associate Editor",
  },
  {
    tm_name: "அண்.சிவ.குணாளன்",
    tm_position: "தலைமை உதவி ஆசிரியர்",
    en_name: "A.S. Kunalan",
    en_postion: "Chief Sub Editor",
  },
  {
    tm_name: "ஜெ.சிவகுமார்",
    tm_position: "துணைத் தலைமை உதவி ஆசிரியர்",
    en_name: "J. Sivakumar",
    en_postion: "Deputy Chief Sub Editor",
  },
];
